import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import ExternalNavbar from '../../components/external-navbar';
import ExternalFooter from '../../components/external-footer';
import '../shared/external.css';

const TITLE = 'Contact us'

class Contact extends React.Component {
  constructor(props) {
    super(props);

    this.state = {}
  }
  render() {
    return(
    <>
      <Helmet>
        <title>{ TITLE }</title>
      </Helmet>

      <ExternalNavbar />

      <div class="container-fluid bg-white">
        <div class="row justify-content-center">
        	<div class="title col-12 col-lg-8">
        		<h2 class="text-center pb-3 pt-5"><b>Get in Touch</b></h2>
          </div>
      	</div>
      </div>
      <div class="container-fluid">
      	<div class="row justify-content-center">
      		<div class="media-container-column col-lg-5 pb-3 text-left">
				  <h5><b>CLOSED</b></h5>
          <p> If you wish to keep in touch with any of our team members and/or be added to our reopening contact list, please email us your info: name, phone number, email address and/or address at VorAkers@Gmail.com</p>
			  </div>
			  <div class="media-container-column col-lg-4 pb-3 text-left">
			  <h5><b>MAP:</b></h5>
			  <a href="http://maps.google.com/maps?f=q&hl=en&geocode=&q=14445+Miramar+Parkway+miramar+FL+33027&sll=37.0625,-95.677068&sspn=33.626896,56.25&ie=UTF8&ll=25.981348,-80.337846&spn=0.009317,0.013733&z=16">
              <button className="btn btn-outline-secondary btn-lg">View on Google Maps</button>
            </a>

        <h5 class="pt-3"><b>FACEBOOK:</b></h5>
        <a href="https://www.facebook.com/sushinthai/">
              <button className="btn btn-outline-secondary btn-lg">Like Us on Facebook</button>
            </a>
			</div>
      	</div>

      </div>


      <div class="container-fluid bg-grey">
           <div class="row">
              <div class="col-sm-4 p-3 pt-4 pb-4 pl-4">
                 <img src={require('./sushiroll.jpg')} width='100%' height='600px' />
              </div>
              <div class="col-sm-4 p-3 pt-4 pb-4">
                 <img src={require('./takeout4.jpg')} width='100%' height='600px' />
              </div>
              <div class="col-sm-4 p-3 pt-4 pb-4">
                 <img src={require('./takeout3.jpg')} width='100%' height='600px' />
              </div>
           </div>
        </div>

      <ExternalFooter />
    </>
    );
  }
}

export default Contact;
