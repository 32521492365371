import React, { Component } from 'react';
import './index.css';


class ExternalNavbar extends React.Component {
    render() {
      return(
        <>
          <nav className="navbar navbar-expand-md">
          <a className="navbar-brand" href="/"><img src={require('./blacklogo.png')} width="60" />Sushi N Thai West Miramar</a>
             <button className="navbar-toggler navbar-light" type="button" data-toggle="collapse" data-target="#main-navigation">
                <span className="navbar-toggler-icon"></span>
             </button>
             <div className="collapse navbar-collapse" id="main-navigation">
                <ul className="navbar-nav">
                   <li className="nav-item ml-auto">
                      <a className="nav-link" href="menu">Menu</a>
                   </li>
                   <li className="nav-item ml-auto">
                      <a className="nav-link" href="contact">Contact</a>
                   </li>

                </ul>

             </div>
          </nav>
        </>
      );
    }
  }

  export default ExternalNavbar;
