
import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import ExternalNavbar from '../../components/external-navbar';
import ExternalFooter from '../../components/external-footer';
import '../shared/external.css';

const TITLE = 'Sushi N Thai West Miramar'


class LandingPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {}
  }
  render() {
    return(
      <>
        <Helmet>
          <title>{ TITLE }</title>
        </Helmet>

        <ExternalNavbar />

        <header className="page-header header container-fluid">
          <div className="overlay"></div>
          <div className="description">
            <h1><b>Sushi-N-Thai West Miramar</b></h1>
            <p>Sushi N Thai West Miramar has now closed its operations as of July 30th, 2023. <br></br><br></br>
            It has been a privilege and honor to have served you, your loved ones, your friends and your neighbors in the Miramar and Pembroke Pines areas these past 16 years.  Our entire team will miss you all, our customers and our friends who have become our loved ones too.<br></br>
            <br></br>
            It is our intention to reopen again somewhere in the South Florida area.  If you wish to keep in touch with any of our team members and/or be added to our reopening contact list, please email us your info:  name, phone number, email address and/or address at <u>VorAkers@Gmail.com</u><br></br>
            <br></br>Until we meet again,<br></br>
            <b>STAY SAFE & BE WELL </b><br></br>
            </p>
          </div>
        </header>

        <div class="container-fluid bg-grey">
           <div class="row">
              <div class="col-sm-3 p-3 pt-4 pb-4 pl-4">
                 <img src={require('./curries2.jpg')} width='100%' height='400px' />
              </div>
              <div class="col-sm-3 p-3 pt-4 pb-4">
                 <img src={require('./sushiboat.jpg')} width='100%' height='400px' />
              </div>
              <div class="col-sm-3 p-3 pt-4 pb-4">
                 <img src={require('./takeout.jpg')} width='100%' height='400px' />
              </div>
              <div class="col-sm-3 p-3 pt-4 pb-4">
                 <img src={require('./padthai.jpg')} width='100%' height='400px' />
              </div>
           </div>
        </div>

        <ExternalFooter />

      </>
    );
  }
}

export default LandingPage;
