import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import ExternalNavbar from '../../components/external-navbar';
import ExternalFooter from '../../components/external-footer';
import '../shared/external.css';

const TITLE = 'Menu'

class Menu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {}
  }
  render() {
    return(
      <>
        <Helmet>
          <title>{ TITLE }</title>
        </Helmet>

        <ExternalNavbar />

        <div class="container-fluid bg-white about-description">
           <div class="row">
              <div class="col-sm-1"></div>
              <div class="col-sm-10">
                  We are now closed.

              </div>
              <div class="col-sm-1"></div>
           </div>
        </div>
        <div class="container-fluid bg-grey">
           <div class="row">
              <div class="col-sm-4 p-3 pt-4 pb-4 pl-4">
                 <img src={require('./dinner1.png')} width='100%' height='300px' />
              </div>
              <div class="col-sm-4 p-3 pt-4 pb-4">
                 <img src={require('./sushi.jpg')} width='100%' height='300px' />
              </div>
              <div class="col-sm-4 p-3 pt-4 pb-4">
                 <img src={require('./donuts.jpg')} width='100%' height='300px' />
              </div>
           </div>
        </div>

        <ExternalFooter />
      </>
    );
  }
}

export default Menu;
