import React, { Component } from 'react';
import './index.css';

class ExternalFooter extends React.Component {
  render() {
    return(
      <>
        <footer className="int-page-footer">
           <div className="container">
              <div className="row">
                 <div className="col-lg-4 col-md-4 col-sm-12 text-left">
                 <h6 className="text-uppercase font-weight-bold">CLOSED</h6>
                 </div>
              </div>
           </div>
           <div className="footer-copyright text-center">© 2022 Copyright: SushiNThaiWestMiramar.com</div>
        </footer>
      </>
    );
  }
}

export default ExternalFooter;
