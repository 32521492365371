import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import { Navbar, Nav, NavItem, NavDropdown, MenuItem } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import Landing from './pages/landing-page/index.js'
import Menu from './pages/menu'
import Contact from './pages/contact'
import ReactGA from 'react-ga';
const TRACKING_ID = "UA-230204781-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);


// Maps out every URL that has a component and links that URL with corresponding component
class App extends React.Component {

  setGA = () => {
    ReactGA.initialize('UA-230204781-1');
    ReactGA.pageview('Init page view');
  };
  componentDidMount(){
    this.setGA();
  }

  render() {
    return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path='/' component={Landing} />
          <Route exact path='/menu' component={Menu} />
          <Route exact path='/contact' component={Contact} />
        </Switch>
      </Router>
    </div>
  );
  }
}

export default App;
